// 404.js
import FeedbackButton from '@/components/ui/Feedback/FeedbackButton';
import Link from 'next/link';

export default function FourOhFour() {
  return (
    <div className="h-screen flex items-center justify-center px-5 ">
      <div className="max-w-xl  -mt-20">
        <h1 className="font-medium text-4xl ">404</h1>
        <p className="mb-5">
          The page you are looking was not found. This should normally not
          happen. If you think this is a bug, please leave us some feedback.
        </p>
        <div className="flex flex-column gap-2">
          <Link legacyBehavior href="/">
            <a className="btn  btn-default ">Go back home</a>
          </Link>
          <FeedbackButton
            placeholder={
              'Hi, the page was not found but this was probably an error. Please have a look. Many thanks'
            }
          />
        </div>
      </div>
    </div>
  );
}
